import {useState, useEffect} from "react"
import { lang } from './lang'
import { FaPhoneAlt, FaFacebookF, FaEnvelope, FaTwitter, FaYoutube, FaTiktok } from 'react-icons/fa';
import { BsLine } from "react-icons/bs";
import Visitors from './Visitors';  
import api from './api'

const Footer = (props) => {
    const [menus, setMenus] = useState([]);
    const init = props.init;
    const styles = props.styles;
    useEffect(()=>{
        const fetchInit = async () =>{
            try {
              const response = await api.get('/menu?type=footer-menu');
              setMenus(response.data.results);
              
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
          }
        fetchInit();
    },[]);

  return (
    <>
    <div className={"container "+styles["footer-container"]}>
        <div className={styles["footer-info"]}>
            <h3>{lang('เว็บไซต์ที่เกี่ยวข้อง','Links')}</h3>
            <ul className={styles["footer-menu"]}>
              {menus.map((item, key) =>(
                  <li key={key}>
                      <a href={item.link} target={item.target}>
                          <p>{lang(item.menu_th,item.menu_en)}</p>
                      </a>
                  </li>
              ))}
            </ul>
            <p></p>
            <p></p>
            <h3>{lang('ติดต่อเรา','Contact us')}</h3>
            <p>{lang(init.address_th,init.address_en)}</p>
            <p>{lang(init.open_info_th,init.open_info_en)}</p>
        </div>
        <div className={styles["social-info"]}>
            <h3 className={styles["visitor-stats"]}>
                {lang('สถิติผู้เข้าชม: ','Visitor Stats: ')}</h3>
            <Visitors />
            <ul className={styles["footer-menu"]+" pt-5"}>
            <li><a href={process.env.PUBLIC_URL + "/page_sitemap.xml"} target="_blank" rel="noopener noreferrer">Sitemap</a></li>
            </ul>
        </div>
    </div>
    <div className={"container "+styles["copyright"]}>
        <div className="left">
            <p>{lang('© สงวนลิขสิทธิ์ เว็บไซต์'+init.name_th+'โดย สำนักหอสมุด มหาวิทยาลัยเชียงใหม่','Copyright © 2022 Chiang Mai University Library. All rights reserved','Copyright © 2022 Chiang Mai University Library. All rights reserved')}</p>
            <a href={process.env.PUBLIC_URL + "/panel"} target="_blank" rel="noopener noreferrer">(ระบบหลังบ้าน)</a>
        </div>
        <div className="right">
            <ul className={styles["social-link"]}>
                {(init.facebook&&init.facebook!=='')?<li><a href={init.facebook} target="_blank" rel="noreferrer" aria-label="Facebook" className="rounded-btn"><FaFacebookF /></a></li>:""}
                {(init.line&&init.line!=='')?<li><a href={init.line} target="_blank" rel="noreferrer" aria-label="Line" className="rounded-btn"><BsLine /></a></li>:""}
                {(init.twitter&&init.twitter!=='')?<li><a href={init.twitter} target="_blank" rel="noreferrer" aria-label="Twitter" className="rounded-btn"><FaTwitter /></a></li>:""}
                {(init.tiktok&&init.tiktok!=='')?<li><a href={init.tiktok} target="_blank" rel="noreferrer" aria-label="Ticktok" className="rounded-btn"><FaTiktok /></a></li>:""}
                {(init.youtube&&init.youtube!=='')?<li><a href={init.youtube} target="_blank" rel="noreferrer" aria-label="Youtube" className="rounded-btn"><FaYoutube /></a></li>:""}
                {(init.email&&init.email!=='')?<li><a href={`mailto:`+init.email} aria-label="Email" className="rounded-btn"><FaEnvelope /></a></li>:""}
                {(init.phone&&init.phone!=='')?<li><a href={`tel:`+init.phone} aria-label="Telephone" className="rounded-btn"><FaPhoneAlt /></a></li>:""}
            </ul>
        </div>
    </div>
    </>
  )
}

export default Footer