import React, { useEffect, lazy } from "react"
import { Routes, Route } from "react-router-dom"
import Layout from "./components/Layout"
import ReactGA from 'react-ga';

import Home from "./views/Home"
import Ebook from "./views/Ebook"
import Article from "./views/Article"
import Resource from "./views/Resource"
import Booking from "./views/Booking"

const TRACKING_ID = "G-F7QPLT4R31"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);


// const Home = lazy(() => wait(1000).then(() => import("./views/Home")))
// const Home = lazy(() => import("./views/Home"))
// const Ebook = lazy(() => import("./views/Ebook"))
// const Article = lazy(() => import("./views/Article"))
// const Resource = lazy(() => import("./views/Resource"))
// const Booking = lazy(() => import("./views/Booking"))
// const About = lazy(() => import("./views/About").then(module => {
//   return { default: module.About }
// }))

function App() {
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
        <Route path="/" element={<Layout />} >
            <Route path="*" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/books" element={<Ebook bookType="all" />} />
            <Route path="/books/:bookType" element={<Ebook bookType="" />} />
            <Route path="/resources" element={<Ebook bookType="all" />} />
            <Route path="/journal" element={<Ebook bookType="journal" />} />
            <Route path="/journals" element={<Ebook bookType="journal" />} />
            <Route path="/e-journal" element={<Ebook bookType="e-journal" />} />
            <Route path="/e-book" element={<Ebook bookType="e-book" />} />
            <Route path="/book/:bookId" element={<Resource />} />
            <Route path="/activities" element={<Article />} />
            <Route path="/article/:articleId" element={<Article />} />
            <Route path="/about" element={<Article id="3" />} />
            <Route path="/services" element={<Article id="2" />} />
            {/* <Route path="/rsvn" element={<Booking />} /> */}
        </Route>
    </Routes>
  );
}

export default App;
